import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "16",
  class: "fill-current"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m7.773 6.4.206 3.5a.375.375 0 0 0 .374.353h1.293a.375.375 0 0 0 .374-.353l.206-3.5a.375.375 0 0 0-.374-.4h-1.7a.375.375 0 0 0-.374.4Zm2.539 5.6A1.313 1.313 0 1 1 9 10.687 1.31 1.31 0 0 1 10.312 12M10.3.75a1.5 1.5 0 0 0-2.6 0l-7.5 13A1.5 1.5 0 0 0 1.5 16h15a1.5 1.5 0 0 0 1.3-2.25zM1.662 14.219 8.837 1.781a.187.187 0 0 1 .325 0l7.175 12.437a.187.187 0 0 1-.162.281H1.824a.187.187 0 0 1-.162-.281Z" }, null, -1)
  ])))
}
export default { render: render }
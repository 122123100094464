import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "38",
  height: "38",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "37.894",
      height: "37.894",
      fill: "#16123B",
      "fill-opacity": ".5",
      rx: "10"
    }, null, -1),
    _createElementVNode("rect", {
      width: "36.894",
      height: "36.894",
      x: ".5",
      y: ".5",
      stroke: "#fff",
      "stroke-opacity": ".07",
      rx: "9.5"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#fff",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "4",
      d: "m11.233 26.66 15.97-15.969m-15.97 0 15.97 15.97"
    }, null, -1)
  ])))
}
export default { render: render }
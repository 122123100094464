import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15.75",
  height: "18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M15.187 2.812h-2.9L11.1.819A1.69 1.69 0 0 0 9.647 0H6.1a1.69 1.69 0 0 0-1.445.819l-1.2 1.993H.562A.56.56 0 0 0 0 3.375v.563a.56.56 0 0 0 .562.562h.562l.746 11.918A1.69 1.69 0 0 0 3.554 18H12.2a1.687 1.687 0 0 0 1.684-1.582L14.625 4.5h.562a.56.56 0 0 0 .563-.562v-.563a.56.56 0 0 0-.563-.563M6.1 1.687h3.547l.675 1.125H5.428Zm6.1 14.625H3.555L2.816 4.5h10.118Z" }, null, -1)
  ])))
}
export default { render: render }
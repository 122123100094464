import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15.583",
  height: "12"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#42d392",
      d: "M13.918.119 4.953 9.084 1.659 5.79a.41.41 0 0 0-.578 0l-.964.964a.41.41 0 0 0 0 .578l4.547 4.547a.41.41 0 0 0 .578 0L15.464 1.662a.41.41 0 0 0 0-.578L14.5.12a.41.41 0 0 0-.582-.001",
      "data-name": "check-regular (2)"
    }, null, -1)
  ])))
}
export default { render: render }
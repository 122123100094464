import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 21.799 14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m7.414 13.828.955-.955a.584.584 0 0 0-.008-.834L4.432 8.265h16.783a.584.584 0 0 0 .584-.584V6.318a.584.584 0 0 0-.584-.584H4.431L8.36 1.96a.584.584 0 0 0 .008-.834L7.413.171a.584.584 0 0 0-.826 0L.172 6.586a.584.584 0 0 0 0 .826l6.416 6.416a.584.584 0 0 0 .826 0" }, null, -1)
  ])))
}
export default { render: render }
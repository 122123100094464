import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 23.997 23.997"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0M8.13 8.128a1.548 1.548 0 1 1-1.55 1.548 1.547 1.547 0 0 1 1.548-1.548Zm8.515 9.289H7.354a.774.774 0 0 1 0-1.548h9.289a.774.774 0 0 1 0 1.548Zm-.774-6.193a1.548 1.548 0 1 1 1.548-1.548 1.547 1.547 0 0 1-1.55 1.548Z"
    }, null, -1)
  ])))
}
export default { render: render }
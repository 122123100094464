import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0m3.87 7.354A1.548 1.548 0 1 1 14.321 8.9a1.547 1.547 0 0 1 1.548-1.546Zm-7.741 0A1.548 1.548 0 1 1 6.58 8.9a1.547 1.547 0 0 1 1.548-1.546Zm4.258 13.16h-.774a6.97 6.97 0 0 1-6.914-6.1.777.777 0 0 1 .769-.871H18.53a.775.775 0 0 1 .769.871 6.97 6.97 0 0 1-6.914 6.099Z"
    }, null, -1)
  ])))
}
export default { render: render }
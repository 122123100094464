<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { EChartsOption } from 'echarts';
import vAnimatedNumber from '../plugins/animatedNumberDirective';
import { LoggedTimeECElementEvent } from '@/composables/useLoggedTimeStatsChart';
import { PieChartDataItem } from '@/models/PieChartDataItem';
import { percentageRatio } from '@/utilities/Helpers';

const props = withDefaults(defineProps<{
	data?: PieChartDataItem[];
	showLegend?: boolean;
}>(), {
	data: () => [],
	showLegend: true,
});

use([
	CanvasRenderer,
	PieChart,
]);

const selectedData = ref<PieChartDataItem>();

watch(() => props.data, (newData) => {
	selectedData.value = undefined;
	const [firstData] = newData;

	if (!firstData) {
		return;
	}

	const combinedValue = newData.reduce((a, b) => a + b.value, 0);
	const percent = percentageRatio(combinedValue, firstData.value) || 0;
	selectedData.value = {
		...firstData,
		value: percent,
	};
}, { immediate: true });

const setStatsOnMouseOver = (params: LoggedTimeECElementEvent) => {
	selectedData.value = {
		label: params.data.name,
		value: params.percent ?? params.data.value,
	};
};

const formatNumber = (value: number) => String(Math.round(value));

const option = computed<EChartsOption>(() => ({
	series: {
		type: 'pie',
		radius: ['65%', '95%'],
		label: undefined,
		animationDuration: 600,
		animationDurationUpdate: 600,
		itemStyle: {
			borderRadius: 5,
			borderColor: 'rgb(32, 28, 75)',
			borderWidth: 2,
		},
		data: props.data.map((config) => ({
			name: config.label,
			value: config.value,
			itemStyle: { color: config.color },
			emphasis: {
				scale: true,
			},
		})),
	},
}));
</script>

<template>
	<!-- eslint-disable @intlify/vue-i18n/no-deprecated-i18n-component -->
	<div>
		<div
			class="grid fullhd:flex fullhd:items-center"
			:class="{
				'fullhd:gap-4 gap-8': showLegend,
			}"
		>
			<div class="p-3 bg-primary-400 w-fit rounded-full">
				<div class="h-60 w-60 relative">
					<div
						v-if="selectedData"
						class="absolute top-0 z-20 inset-0 flex items-center flex-col justify-center pointer-events-none px-16"
					>
						<p class="text-sm">
							{{ selectedData.label }}
						</p>
						<div class="text-2xl font-bold flex">
							<p
								:key="selectedData.label"
								v-animated-number:[formatNumber].single.skip="selectedData.value"
							/>%
						</div>
					</div>
					<VChart
						class="relative z-10"
						:option="option"
						@click="(params) => setStatsOnMouseOver(params as LoggedTimeECElementEvent)"
						@mouseover="(params) => setStatsOnMouseOver(params as LoggedTimeECElementEvent)"
						@focus="() => {}"
					/>
				</div>
			</div>
			<div
				v-if="showLegend"
				class="fullhd:block grid grid-cols-2 gap-y-4 fullhd:space-y-2 gap-x-8"
			>
				<div
					v-for="entry in data"
					:key="entry.label"
					class="flex items-center gap-3"
				>
					<div
						class="h-5 w-8 rounded-full"
						:style="{ backgroundColor: entry.color }"
					/>
					<p class="whitespace-nowrap">
						{{ entry.label }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

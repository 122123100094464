import type { RouteLocationRaw } from 'vue-router';
import { MaybeRef } from 'vue';
import type { ColorTheme } from './ColorTheme';
import type { IconType } from '@/models/Icon';
import { UserRight } from '@/api/rights';
import i18n from '@/i18n';

export type LinkItem = string | RouteLocationRaw;

export type Check = 'hasQuotaListAccess' | MaybeRef<boolean> | { userRight: UserRight };

export type NavigationItemBadge = {
	text: string;
	color: ColorTheme;
};

export interface NavigationItem {
	link: LinkItem;
	icon?: IconType;
	label: string;
	check?: Check;
	badge?: NavigationItemBadge;
}

export const getNavigationItems = (): NavigationItem[] => [
	{
		link: { name: 'Dashboard' },
		icon: 'plus',
		label: i18n.global.t('navigation.dashboard'),
	},
	{
		link: { name: 'History' },
		icon: 'heart',
		label: i18n.global.t('navigation.calendar'),
	},
	{
		link: { name: 'PersonalReport' },
		icon: 'doc',
		label: i18n.global.t('navigation.personalReport'),
	},
	{
		check: 'hasQuotaListAccess',
		link: { name: 'QuotaReport' },
		icon: 'quota-monitoring',
		label: i18n.global.t('navigation.quotaMonitoring'),
	},
	{
		link: { name: 'TopicOverview' },
		icon: 'list',
		label: i18n.global.t('navigation.topicOverview'),
	},
	{
		link: { name: 'Admin' },
		icon: 'warning',
		label: i18n.global.t('navigation.admin'),
		check: {
			userRight: 'VIEW_ADMIN_PANEL',
		},
	},
	{
		link: { name: 'ManageQuotaLists' },
		icon: 'warning',
		label: i18n.global.t('navigation.manageQuotaLists'),
		check: {
			userRight: 'MANAGE_QUOTA_LISTS',
		},
	},
];

export const getUserMenuItems = (): NavigationItem[] => [
	{
		label: i18n.global.t('navigation.logOut'),
		link: { name: 'Logout' },
	},
	{
		label: i18n.global.t('navigation.settings'),
		link: { name: 'User' },
	},
];

export const hasUserRightsCheck = (check: Check): check is { userRight: UserRight } => typeof check === 'object' && Object.keys(check).includes('userRight');

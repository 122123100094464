import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Gruppe 305",
  viewBox: "0 0 19.499 17.999"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "a" }, [
        _createElementVNode("path", {
          fill: "none",
          d: "M0 0h19.499v17.999H0z",
          "data-name": "Rechteck 273"
        })
      ])
    ], -1),
    _createElementVNode("g", {
      "clip-path": "url(#a)",
      "data-name": "Gruppe 304"
    }, [
      _createElementVNode("path", {
        fill: "#a09eba",
        d: "M18.077.693a1.384 1.384 0 0 1 0 2.768H7.615a1.384 1.384 0 0 1 0-2.768Zm0 6.922a1.386 1.386 0 0 1 0 2.77H7.615a1.385 1.385 0 0 1-.074-2.77zm0 6.923a1.385 1.385 0 0 1 .075 2.769H7.615a1.385 1.385 0 1 1-.074-2.769zM4.153 2.077A2.076 2.076 0 1 1 2.076 0a2.076 2.076 0 0 1 2.077 2.077m0 6.923a2.076 2.076 0 1 1-2.077-2.076A2.077 2.077 0 0 1 4.153 9m0 6.923a2.076 2.076 0 1 1-2.077-2.077 2.076 2.076 0 0 1 2.077 2.077",
        "data-name": "Pfad 74"
      })
    ], -1)
  ])))
}
export default { render: render }
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "23.773",
  height: "18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m22.064.188-15.04 15.04-5.321-5.317a.645.645 0 0 0-.912 0l-.608.608a.645.645 0 0 0 0 .912l6.381 6.381a.645.645 0 0 0 .912 0l16.1-16.1a.645.645 0 0 0 0-.912l-.608-.608a.645.645 0 0 0-.911 0Z",
      class: "w-5 h-5",
      "data-name": "check-light (1)"
    }, null, -1)
  ])))
}
export default { render: render }
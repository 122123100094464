import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 576 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m101.9 91.4 30.3 50.5c-4 3.5-8 7.2-12 11.2-71 71-60.2 151-32.7 191.3 5 7.3 15.2 7.3 21.4 1l138.5-138.5c4 10.8 7.8 26.2 9.7 47.2 4.2 46.5-1.6 116.8-32.7 216.4-3 9.7-1.3 20.3 4.8 28.5s15.6 13 25.8 13h64c16.1 0 29.8-12 31.8-28 12.4-99-2.3-207.2-31.7-292h126.6c2.8 0 5.4-1.5 6.9-3.9l19.6-32.7c3.1-5.2 10.6-5.2 13.7 0l19.6 32.7c1.4 2.4 4.1 3.9 6.9 3.9H559c8.8 0 16.1-7.2 14.4-15.9C564.3 128.2 515.4 64 415 64c-43.7 0-77.6 12.2-102.8 29.8C295.5 49.4 247 0 159 0 58.6 0 9.7 64.2.6 112.1-1.1 120.8 6.2 128 15 128h46.7c2.8 0 5.4-1.5 6.9-3.9l19.5-32.7c3.1-5.2 10.6-5.2 13.7 0z"
    }, null, -1)
  ])))
}
export default { render: render }
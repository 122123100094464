import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 21.078 25.702"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      stroke: "rgba(0,0,0,0)",
      "stroke-miterlimit": "10",
      "data-name": "Komponente 41 – 5"
    }, [
      _createElementVNode("path", {
        fill: "rgba(255,255,255,0.5)",
        d: "M19.871 5.829h-2.7a1.926 1.926 0 0 1-1.926-1.926V1.207Z",
        "data-name": "Pfad 59"
      }),
      _createElementVNode("path", {
        fill: "#42d392",
        d: "M15.25 25.202H5.121A4.63 4.63 0 0 1 .5 20.578V5.828a4.627 4.627 0 0 1 4.621-4.621h8.818v2.694a3.24 3.24 0 0 0 3.234 3.234h2.7v13.442a4.63 4.63 0 0 1-4.623 4.625ZM4.411 14.21a.35.35 0 0 0-.243.1l-.321.324a.34.34 0 0 0 0 .483l3.391 3.395a.35.35 0 0 0 .241.1.34.34 0 0 0 .243-.1l8.563-8.562a.347.347 0 0 0 0-.485l-.324-.322a.35.35 0 0 0-.243-.1.34.34 0 0 0-.242.1l-7.993 8-2.827-2.828a.35.35 0 0 0-.245-.105Zm15.461-8.382h-2.7a1.93 1.93 0 0 1-1.927-1.927V1.207l4.621 4.619Z",
        "data-name": "Differenzmenge 2"
      })
    ], -1)
  ])))
}
export default { render: render }
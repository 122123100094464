<script setup lang="ts">
import { useToast } from 'vue-toastification';
import { z } from 'zod';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import AppInput from './AppInput.vue';
import { useActivitiesStore } from '@/store/activities';
import AppButton from '@/components/AppButton.vue';
import { zStringReq } from '@/utilities/Helpers';

const emit = defineEmits<{
	(e: 'cancel', value: boolean): void;
}>();

const { t } = useI18n();
const activitiesStore = useActivitiesStore();
const toast = useToast();

const validationSchema = toTypedSchema(
	z.object({
		activityName: zStringReq(t('validation.fieldIsRequired')),
	}),
);

const { values, meta } = useForm({
	validationSchema,
});

const close = () => {
	emit('cancel', false);
};

const computedMessages = computed(() => ({
	successCreatedActivity: t('notifications.successCreatedActivity'),
}));

const onConfirm = async (activityName: string | undefined) => {
	if (activityName === undefined) {
		return;
	}
	await activitiesStore.createActivity(activityName);
	toast.success(`${computedMessages.value.successCreatedActivity} "${activityName}"`);
	close();
};
</script>

<template>
	<form>
		<div class="flex">
			<div class="inline-block mr-6 text-secondary">
				{{ $t('common.general') }}
			</div>
		</div>
		<div class="text-xl mt-10 mb-3">
			{{ $t('admin.activityName') }}
		</div>
		<AppInput
			v-model="values.activityName"
			class="text-white bg-primary-300 rounded-md w-full px-2 text-2xl border border-primary-900 py-2"
			:placeholder="$t('admin.activityName')"
			name="activityName"
		/>
		<div class="flex justify-between mt-12">
			<AppButton
				size="sm"
				:disabled="!meta.valid"
				@click="onConfirm(values.activityName)"
			>
				{{ $t('admin.createActivity') }}
			</AppButton>
			<AppButton
				size="sm"
				color="outline"
				@click="close"
			>
				{{ $t('common.cancel') }}
			</AppButton>
		</div>
	</form>
</template>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "14",
  height: "14",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#733BFF",
      d: "M7 0a7 7 0 1 1 0 14A7 7 0 0 1 7 0m-.656 3.281V7c0 .219.11.424.292.547l2.625 1.75c.301.202.709.12.91-.183a.654.654 0 0 0-.182-.91L7.656 6.65V3.281A.655.655 0 0 0 7 2.625a.655.655 0 0 0-.656.656"
    }, null, -1)
  ])))
}
export default { render: render }
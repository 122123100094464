import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M13.83 10.17a4.01 4.01 0 0 0-5.66 0l-4 4a4.01 4.01 0 0 0 0 5.66 4.01 4.01 0 0 0 5.66 0l1.1-1.1m-.76-4.9a4.01 4.01 0 0 0 5.66 0l4-4a4.01 4.01 0 0 0 0-5.66 4.01 4.01 0 0 0-5.66 0l-1.1 1.1"
    }, null, -1)
  ])))
}
export default { render: render }
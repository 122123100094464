import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m9.7 8 5.128-5.128 1.058-1.058a.4.4 0 0 0 0-.566L14.754.116a.4.4 0 0 0-.566 0L8 6.3 1.815.117a.4.4 0 0 0-.566 0L.117 1.249a.4.4 0 0 0 0 .566L6.3 8 .117 14.186a.4.4 0 0 0 0 .566l1.132 1.132a.4.4 0 0 0 .566 0L8 9.7l5.128 5.128 1.058 1.058a.4.4 0 0 0 .566 0l1.132-1.132a.4.4 0 0 0 0-.566Z" }, null, -1)
  ])))
}
export default { render: render }
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20.247",
  height: "18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m14.141 12.126 1.125-1.125a.283.283 0 0 1 .482.2v5.111a1.69 1.69 0 0 1-1.687 1.687H1.687A1.69 1.69 0 0 1 0 16.313V3.94a1.69 1.69 0 0 1 1.687-1.688H11.3a.283.283 0 0 1 .2.482l-1.123 1.125a.28.28 0 0 1-.2.081h-8.49v12.373H14.06v-3.99a.28.28 0 0 1 .081-.197m5.5-7.093-9.231 9.231-3.178.352a1.452 1.452 0 0 1-1.6-1.6l.352-3.178L15.217.604a2.05 2.05 0 0 1 2.907 0l1.519 1.519a2.06 2.06 0 0 1 0 2.911Zm-3.468 1.086-2.042-2.044L7.6 10.611l-.257 2.3 2.3-.257Zm2.278-2.8-1.519-1.52a.367.367 0 0 0-.52 0l-1.086 1.086 2.042 2.042 1.086-1.086a.375.375 0 0 0-.003-.524Z" }, null, -1)
  ])))
}
export default { render: render }
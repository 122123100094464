import { DateTime } from 'luxon';
import { QuotaBeat } from './QuotaBeats';

export interface QuotaEntry {
	clientId: string;
	name: string;
	logCount: number;
	monthlyHours: number;
	loggedHours: number;
	alert: boolean;
	isExtra: boolean;
	isQuota: boolean;
	beats: QuotaBeat[];
	projectId?: string;
	projects?: QuotaEntry[];
}

export interface QuotaMonthlyEntry {
	name: string;
	month: DateTime;
	monthlyHours: number;
	loggedHours: number;
}

export type QuotaOverviewEntry = QuotaMonthlyEntry & {
	id: string;
	monthlyEntries: QuotaMonthlyEntry[];
	usageInPercent: number;
	usageStatus: 'exceeded' | 'undershot' | null;
};

export const removeNormalProjectQuotaEntries = (quotaEntry: QuotaEntry) => {
	if ((quotaEntry.isExtra && quotaEntry.projectId) || !quotaEntry.projectId) {
		return true;
	}

	return false;
};

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 741.914 741.434"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#733bff",
      d: "M741.914 370.719a142.457 142.457 0 0 1-142.43 142.434h-86.328v85.848a142.45 142.45 0 0 1-142.437 142.434c-78.172 0-141.953-63.785-141.953-142.434v-85.848h-86.324a142.436 142.436 0 1 1 0-284.871h86.328v-86.321a142.2 142.2 0 1 1 284.391 0v86.32h86.328a142.45 142.45 0 0 1 142.425 142.438",
      "data-name": "Pfad 54"
    }, null, -1)
  ])))
}
export default { render: render }
<script setup lang="ts">
import { computed } from 'vue';
import { useVfm, VueFinalModal } from 'vue-final-modal';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import AppButton from '../AppButton.vue';
import TodaysLogCard from '../TodaysLogCard.vue';
import PlaceholderLogCard from '../PlaceholderLogCard.vue';
import TimesIcon from '@/assets/times-icon.svg?component';
import { useMainStore } from '@/store/main';
import { BeatMeta } from '@/models/Beats';

const props = defineProps<{
	modalId: string;
	beat: BeatMeta;
}>();

const emit = defineEmits<{
	(e: 'confirm', closeFn: () => void): void;
	(e: 'confirm-today', closeFn: () => void): void;
	(e: 'update:modelValue', value: boolean): void;
	(e: 'update:loading', value: boolean): void;
	(e: 'before-open'): void;
	(e: 'opened'): void;
	(e: 'before-close'): void;
	(e: 'closed'): void;
	(e: 'cancel'): void;
}>();
const { t } = useI18n();
const mainStore = useMainStore();

const toast = useToast();

const isLogPeriodOver = computed(() => {
	if (props.beat.timestamp < mainStore.today.startOf('day').minus({ days: 7 })) {
		return true;
	}
	return false;
});

const close = () => {
	emit('update:modelValue', false);
};

const onBeforeOpen = () => {
	emit('before-open');
	mainStore.hasAnyModalsOpened = true;
};

const onBeforeClose = () => {
	emit('before-close');
};

const onOpened = () => {
	emit('opened');
};

const { modals } = useVfm();

const onClosed = () => {
	emit('closed');

	if (modals.length === 0) {
		mainStore.hasAnyModalsOpened = false;
	}

	emit('update:loading', false);
};

const computedMessages = computed(() => ({
	successCreateLog: t('notifications.successCreateLog'),
}));

const onConfirm = () => {
	emit('update:loading', true);
	toast.success(computedMessages.value.successCreateLog);
	emit('confirm', close);
};

const onConfirmToday = () => {
	emit('update:loading', true);
	toast.success(computedMessages.value.successCreateLog);
	emit('confirm-today', close);
};

const onSubmit = isLogPeriodOver.value ? onConfirmToday : onConfirm;

</script>

<template>
	<VueFinalModal
		:modal-id="modalId"
		class="fixed h-full w-full"
		overlay-class="backdrop-filter backdrop-brightness-20 backdrop-blur-[10px] !bg-primary-300 !bg-opacity-50 h-full w-full"
		content-class="absolute mt-[206px] bg-primary-300 w-fit max-w-3xl rounded-lg p-8 shadow-modal top-0 mx-auto left-0 right-0 outline-none"
		:content-transition="{
			enterFromClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'translate-y-0',
			leaveFromClass: 'translate-y-0',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
		}"
		:overlay-transition="{
			enterFromClass: 'mobile:opacity-0',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'opacity-100',
			leaveFromClass: 'opacity-100',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0',
		}"
		:esc-to-close="true"
		:teleport-to="false"
		@before-open="onBeforeOpen"
		@before-close="onBeforeClose"
		@opened="onOpened"
		@closed="onClosed"
		@update:model-value="$emit('update:modelValue', $event)"
	>
		<div class="grid grid-cols-2 items-center space-x-24 w-full p-6">
			<div class="flex flex-col col-span-1">
				<div class="text-[42px] leading-[1.15] font-bold">
					{{ $t('modal.shareable.takeOverTheLog') }}
				</div>
				<div class="flex items-center justify-between my-8">
					<AppButton
						:disabled="!beat"
						size="medium"
						autofocus
						tabindex="0"
						@click="onSubmit"
					>
						{{ $t('modal.shareable.submit') }}
					</AppButton>
					<AppButton
						size="medium"
						color="outline"
						tabindex="0"
						@click="emit('cancel')"
					>
						{{ $t('common.cancel') }}
					</AppButton>
				</div>
				<div>
					<span
						v-if="!isLogPeriodOver"
						class="text-sm block text-center"
					>
						{{ $t('modal.shareable.text.first') }} <strong>
							{{ beat.timestamp.toLocaleString() }}
						</strong>
						{{ $t('modal.shareable.text.second') }}
					</span>
					<span
						v-if="isLogPeriodOver"
						class="text-sm block text-center"
					>
						{{ $t('modal.shareable.time.first') }} <strong>{{ $t('modal.shareable.time.second') }}</strong> {{ $t('modal.shareable.time.third') }}
					</span>
				</div>
				<hr
					v-if="!isLogPeriodOver"
					class="text-gray my-4"
				>
				<div
					v-if="!isLogPeriodOver"
					class="justify-center my-0 mx-auto"
				>
					<AppButton
						size="medium"
						color="outline"
						tabindex="0"
						@click="onConfirmToday"
					>
						{{ $t('modal.shareable.logForToday') }}
					</AppButton>
				</div>
			</div>
			<div class="flex flex-col col-span-1 space-y-8">
				<TodaysLogCard
					v-if="beat"
					class="bg-primary-500 min-w-[240px]"
					:beat="beat"
					:editable="false"
				/>
				<PlaceholderLogCard	v-else />
			</div>
		</div>
		<TimesIcon
			class="absolute -top-8 -right-4 mobile:-top-16 mobile:-right-8 fill-current cursor-pointer"
			@click="emit('cancel')"
		/>
	</VueFinalModal>
</template>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25.146",
  height: "25.558"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M25.146 13.077c0 7.291-4.993 12.48-12.367 12.48a12.779 12.779 0 0 1 0-25.558 12.3 12.3 0 0 1 8.569 3.344L17.87 6.688c-4.55-4.39-13.011-1.092-13.011 6.091a8 8 0 0 0 7.92 8.069c5.06 0 6.956-3.628 7.255-5.508h-7.255v-4.4h12.166a11 11 0 0 1 .201 2.137",
      "data-name": "Icon awesome-google",
      opacity: ".5"
    }, null, -1)
  ])))
}
export default { render: render }
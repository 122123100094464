import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "17.91",
  height: "22.18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"#42D392\" data-name=\"Gruppe 189\"><path d=\"M17.9 4.27h-2.49a1.78 1.78 0 0 1-1.78-1.78V0Z\" data-name=\"Pfad 59\"></path><path d=\"M15.41 5.48a3 3 0 0 1-2.99-2.99V0H4.27A4.27 4.27 0 0 0 0 4.27v13.64a4.27 4.27 0 0 0 4.27 4.27h9.37a4.27 4.27 0 0 0 4.27-4.27V5.48ZM12.57 13a.7.7 0 0 1-.5.2H5.83a.7.7 0 1 1 0-1.4h6.24a.7.7 0 0 1 .5 1.2m0-2.82a.7.7 0 0 1-.5.2H5.83a.7.7 0 1 1 0-1.4h6.24a.7.7 0 0 1 .5 1.2\" data-name=\"Pfad 60\"></path><path d=\"M17.9 4.27h-2.49a1.78 1.78 0 0 1-1.78-1.78V0Z\" data-name=\"Pfad 61\"></path><path d=\"M17.9 4.27h-2.49a1.78 1.78 0 0 1-1.78-1.78V0Z\" data-name=\"Pfad 62\"></path></g>", 1)
  ])))
}
export default { render: render }
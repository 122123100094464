import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18.438",
  height: "22.182"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#ff874b",
      d: "m7.35 3.745 2.227-2.227a5.18 5.18 0 0 1 7.326 0 5.23 5.23 0 0 1 1.535 3.663 5.17 5.17 0 0 1-1.523 3.676l-2.226 2.227 2.227 2.227a5.2 5.2 0 0 1-7.351 7.35l-2.227-2.227-7.337-7.339Z",
      "data-name": "Pfad 29"
    }, null, -1)
  ])))
}
export default { render: render }
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "162.5",
  height: "36"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M0 27.1h5.5c-.1 1.4.6 2.8 1.7 3.6 1.2.7 2.6 1.1 4 1 1.1.1 2.2-.2 3.2-.6 1-.3 1.8-1.4 1.8-2.5 0-1.4-1.3-2.5-4-3.2-4.6-1.3-7-2-7.4-2.2-2.6-1.2-4-3.1-4-5.8a6.2 6.2 0 0 1 3.1-5.6c2-1.3 4.3-1.9 6.6-1.8 2.9 0 5.4.6 7.2 1.8 2.1 1.3 3.2 3.7 3.2 6.2h-5.5c-.1-2.5-1.8-3.7-5.1-3.7-.9-.1-1.8.2-2.6.6-.9.3-1.3 1.2-1.3 2.1s.6 1.7 1.4 2c2.1.7 4.1 1.3 6.3 1.8 1.6.3 3.1.9 4.5 1.7 2 1.2 3.2 3.3 3.2 5.6.1 2.2-1 4.4-2.8 5.7-2 1.5-4.6 2.2-8 2.2-3.1 0-5.6-.7-7.7-2.1A7.83 7.83 0 0 1 0 27.1m40.4-9.7v18h-4.9v-2.5h-.1c-1.2 2-3.4 3.1-5.7 3-2.6 0-4.4-.7-5.5-2.1-.9-1.2-1.4-2.9-1.4-5.4v-11h5.1v10.1c0 2.9 1.1 4.4 3.3 4.4 1.4 0 2.4-.4 3.1-1.2q.9-1.2.9-3.9v-9.4zm2.3 18v-18h4.9v2.5h.1c1.2-2 3.4-3.1 5.7-3 2.6 0 4.4.7 5.5 2 .9 1.2 1.4 2.9 1.4 5.4v11.1h-5.1V25.2c0-2.9-1.1-4.4-3.3-4.4-1.4 0-2.4.4-3.1 1.2-.6.8-1 2.2-1 4v9.4zm20.3 0V10.6h5.6v9.5H79v-9.5h5.6v24.8H79V24.7H68.6v10.7zm42.2-7.8H91.8c.2 3.1 1.7 4.6 4.6 4.6.9 0 1.8-.2 2.6-.7.6-.3 1.2-.9 1.4-1.6h4.5c-1.4 4-4.3 6-8.8 6-2.9 0-5.2-.9-7-2.6a9.5 9.5 0 0 1-2.6-6.8 9.1 9.1 0 0 1 8.8-9.5h.7c3.1 0 5.4 1.1 7 3.3 1.8 1.9 2.4 4.4 2.2 7.3m-13.4-3.1h8.4c-.5-2.5-1.8-3.8-4.1-3.8-1.3-.1-2.5.5-3.3 1.4-.6.7-1 1.5-1 2.4m19.7-1.6h-5.1c.1-2 1.2-3.8 2.9-4.7 1.8-.9 3.8-1.4 5.9-1.3 5.5 0 8.2 1.7 8.2 5.1v9.3c0 2 .2 3.3.6 4h-5.2c-.2-.6-.3-1.2-.3-1.7-1.5 1.5-3.6 2.2-6.3 2.2-1.6.1-3.2-.4-4.5-1.3-1.2-1-1.9-2.5-1.8-4-.1-1.6.6-3.2 2-4.1 1.3-.7 2.8-1.2 4.4-1.4 2.8-.3 4.1-.6 4.2-.6q1.8-.45 1.8-1.8c.1-.8-.3-1.6-1-2-.7-.3-1.5-.5-2.3-.4-2.2 0-3.3.9-3.5 2.7m6.8 5.6v-1.8c-.4.3-1.3.6-2.6.8-1 .1-2.1.3-3.1.7-.9.3-1.6 1.2-1.5 2.2 0 1.6 1 2.3 3.1 2.3 2.7-.2 4.1-1.5 4.1-4.2m7.6 6.9V10.6h5.1v24.8zm14-20v14.2c-.1.6.1 1.2.5 1.7.5.3 1.1.5 1.7.4.5 0 1-.1 1.6-.1v3.9c-1 .1-2 .2-3 .2-3.9 0-5.9-1.4-5.9-4.3v-16zm5.1 20V10.6h5.1v9.3h.1c.6-1 1.5-1.7 2.5-2.2.9-.5 2-.7 2.9-.7 2.6 0 4.4.7 5.5 2 .9 1.2 1.4 2.9 1.4 5.4v11.1h-5.1V25.2c0-2.9-1.1-4.4-3.3-4.4-1.4 0-2.4.4-3.1 1.2q-.9 1.2-.9 3.9v9.4z",
      class: "st0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#733bff",
      d: "M144 6.4c0 .6-.3 1.3-.7 1.7s-1.2.7-1.8.7h-1.6v1.6c-.2 1.4-1.4 2.4-2.8 2.3-1.2-.1-2.1-1.1-2.3-2.3V8.9h-1.6c-1.4 0-2.5-1.1-2.5-2.5s1.2-2.5 2.5-2.5h1.6V2.5c0-1.4 1.2-2.5 2.5-2.5a2.41 2.41 0 0 1 2.5 2.4v1.5h1.6c1.4 0 2.6 1.1 2.6 2.5",
      class: "st1"
    }, null, -1)
  ])))
}
export default { render: render }
import { z } from 'zod';
import { groupBy, zStringReq } from '../utilities/Helpers';
import { Beat } from './Beats';
import { Database } from './Supabase';
import { EntryWithTime } from './EntryWithTime';

export interface Project {
	id: string;
	name: string;
	clientId: string;
	description?: string;
	archived?: boolean;
	quotaInfoId?: string;
}

export type ProjectWithTime = Project & EntryWithTime;

export type ProjectMeta = Omit<Project, 'id'>;

export type SupabaseProject = Database['public']['Tables']['projects']['Row'];

export const isProjectActive = (project: Project): project is Project & { archived?: false } => project.archived === undefined || !project.archived;

export const createProjectFromSupabase = (project: SupabaseProject): Project => ({
	id: project.project_id,
	clientId: project.client_id ?? '',
	name: project.name,
	description: project.description ?? undefined,
	archived: project.archived,
	quotaInfoId: project.quota_info_id ?? undefined,
});

export const toSupabaseData = (projectMeta: ProjectMeta): Omit<SupabaseProject, 'project_id' | 'quota_info_id'> => ({
	archived: projectMeta.archived ?? false,
	name: projectMeta.name ?? '',
	client_id: projectMeta.clientId ?? null,
	description: projectMeta.description ?? null,
});

export const projectMetaSchema = z.object({
	clientId: zStringReq(),
	name: zStringReq(),
	archived: z.boolean().optional(),
	description: z.string().optional(),
	quotaInfoId: z.string().optional(),
});

export const isProjectMeta = (input: unknown): input is ProjectMeta => projectMetaSchema.safeParse(input).success;

export const findProjectFromBeat = (projects: Project[], beat: Beat): Project | undefined => projects.find((project) => project.id === beat.projectId);

export const getProjectsWithTimeFromBeats = (allBeats: Beat[], projects: Project[]): ProjectWithTime[] => {
	const beatsWithProject = allBeats.filter((beat) => beat.projectId);
	const beatsGroupedByProject = groupBy(beatsWithProject, 'projectId');

	return Object.entries(beatsGroupedByProject)
		.map(([projectId, projectBeats]) => {
			const project = findProjectFromBeat(projects, projectBeats[0]);
			return (
				{
					id: projectId,
					name: project?.name ?? 'NO NAME FOUND',
					clientId: project?.clientId ?? 'NO NAME FOUND',
					timeIntervalInMs: projectBeats.reduce((prev, curr) => prev + curr.timeIntervalInMs, 0),
					quotaInfoId: project?.quotaInfoId,
					description: project?.description
						? project?.description
						: 'NO DESCRIPTION FOUND',
				});
		});
};

export const sortProjectsByTime = (projects: ProjectWithTime[]): ProjectWithTime[] => [...projects].sort((projectA, projectB) => projectB.timeIntervalInMs - projectA.timeIntervalInMs);

export const getTopProjects = (beats: Beat[], projects: Project[], maxProjects?: number): ProjectWithTime[] => {
	const topProjects = getProjectsWithTimeFromBeats(beats, projects);
	const sortedProjects = sortProjectsByTime(topProjects);
	if (maxProjects !== undefined) {
		return sortedProjects.slice(0, maxProjects);
	}
	return sortedProjects;
};

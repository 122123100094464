import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "19",
  height: "19",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "9.5",
      cy: "9.5",
      r: "9.5",
      fill: "#2A2656"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M8.045 11.511v-.193q.006-.994.177-1.585.175-.59.511-.955.335-.363.807-.659.352-.226.63-.471a2 2 0 0 0 .444-.54q.165-.3.164-.67 0-.393-.187-.688a1.25 1.25 0 0 0-.506-.455 1.5 1.5 0 0 0-.693-.159q-.37 0-.699.165-.33.159-.54.477-.21.313-.227.779H5.608q.028-1.137.545-1.875a3.12 3.12 0 0 1 1.37-1.108 4.7 4.7 0 0 1 1.88-.37q1.13 0 2 .376.87.37 1.364 1.073.495.704.494 1.7 0 .663-.221 1.181a2.9 2.9 0 0 1-.608.91q-.392.39-.926.71-.45.266-.739.556-.284.29-.426.67a2.9 2.9 0 0 0-.142.938v.193zm1.125 3.637q-.567 0-.971-.398a1.3 1.3 0 0 1-.392-.966q-.006-.557.392-.955.404-.397.971-.397.54 0 .95.397a1.304 1.304 0 0 1 .215 1.643q-.188.306-.494.494a1.3 1.3 0 0 1-.67.182"
    }, null, -1)
  ])))
}
export default { render: render }
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 21.078 25.702"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "data-name": "Gruppe 253" }, [
      _createElementVNode("path", {
        stroke: "rgba(0,0,0,0)",
        "stroke-miterlimit": "10",
        d: "M19.37 5.829h-2.7a1.926 1.926 0 0 1-1.926-1.926V1.207Z",
        "data-name": "Pfad 59"
      }),
      _createElementVNode("path", {
        d: "M14.747 25.208H4.623A4.63 4.63 0 0 1 0 20.582V5.831a4.63 4.63 0 0 1 4.623-4.623h8.816v2.7a3.24 3.24 0 0 0 3.236 3.236h2.7v13.438a4.63 4.63 0 0 1-4.628 4.626m-5.833-10.2v3.061h1.545v-3.061H13.5V13.47h-3.041v-3.062H8.914v3.06H5.869v1.54Z",
        "data-name": "Differenzmenge 1"
      })
    ], -1)
  ])))
}
export default { render: render }
import { z } from 'zod';
import { supabase } from '@/lib/supabase';
import { ReasonWithDescription } from '@/models/Reason';

export const getTopReasons = async (userId: string): Promise<ReasonWithDescription[]> => {
	const { data } = await supabase
		.from('top_client_chips')
		.select('*')
		.eq('user_id', userId)
		.limit(10)
		.order('total_time', { ascending: false });

	const topReasonsSchema = z.object({
		activity_id: z.string(),
		activity_name: z.string(),
		client_id: z.string(),
		client_name: z.string(),
		project_id: z.string().nullable(),
		project_name: z.string().nullable(),
		total_time: z.number(),
		user_id: z.string(),
	});

	const isValidData = (input: NonNullable<typeof data>[0]): input is z.infer<typeof topReasonsSchema> => topReasonsSchema.safeParse(input).success;

	return data
		?.filter(isValidData)
		.map((entry): ReasonWithDescription => ({
			activity: { id: entry.activity_id, name: entry.activity_name, archived: null },
			activityId: entry.activity_id,
			client: { id: entry.client_id, name: entry.client_name, isInternal: false },
			clientId: entry.client_id,
			project: entry.project_id && entry.project_name ? { id: entry.project_id, clientId: entry.client_id, name: entry.project_name } : null,
			projectId: entry.project_id ?? null,
		})) ?? [];
};

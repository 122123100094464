import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M13.917 6.333h-5.25v-5.25A.583.583 0 0 0 8.083.5H6.917a.583.583 0 0 0-.583.583v5.25H1.083a.583.583 0 0 0-.583.584v1.167a.583.583 0 0 0 .583.583h5.25v5.25a.583.583 0 0 0 .584.583h1.166a.583.583 0 0 0 .583-.583v-5.25h5.25a.583.583 0 0 0 .584-.584V6.917a.583.583 0 0 0-.583-.584",
      class: "fill-current",
      "data-name": "plus-regular (1)"
    }, null, -1)
  ])))
}
export default { render: render }